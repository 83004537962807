<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<transition name="fade">
			<Message v-if="status" :message="status" type="info" />
		</transition>

		<Message v-if="loading" message="loading" type="info" />

		<template v-else>
			<InputField
				v-model="newId"
				name="Client ID"
				:disabled="!!status"
			/>

			<InputField
				v-model="secret"
				name="Secret"
				:disabled="!!status"
			/>

			<InputField
				v-model="method"
				name="Authentication Method"
				placeholder="client_secret_post"
				:disabled="!!status"
			/>

			<br>

			<InputList
				v-model="responseTypes"
				name="Response Types"
				placeholder="code"
				:disabled="!!status"
				@submit="updateResponseTypes"
			/>

			<br>

			<InputList
				v-model="grantTypes"
				name="Grant Types"
				placeholder="authorization_code"
				:disabled="!!status"
				@submit="updateGrantTypes"
			/>

			<br>

			<InputList
				v-model="redirectUris"
				name="Redirect URIs"
				placeholder="https://jwt.io"
				:disabled="!!status"
				@submit="updateRedirectUris"
			/>

			<br>

			<div class="navigation">
				<Button @click="back">
					Back
				</Button>

				<Button :disabled="!!status" @click="setClient">
					Submit
				</Button>
			</div>
		</template>
	</div>
</template>

<script>
import api from "@/api";

import InputField	from "@/components/InputField.vue";
import InputList	from "@/components/InputList.vue";
import Button		from "@/components/Button.vue";
import Message		from "@/components/Message.vue";

export default {
	name: "Client",

	components: {
		InputField,
		Button,
		InputList,
		Message
	},

	props: {
		id: {
			type: String,
			required: false,
			default: ""
		}
	},

	data () {
		return {
			error: undefined,
			status: undefined,
			loading: true,

			newId: "",
			secret: "",
			method: "",
			responseTypes: [],
			grantTypes: [],
			redirectUris: []
		};
	},

	async beforeMount () {
		try {
			this.newId = this.id;

			const clients = await api.getClients();
			const client = clients.find(client => client.client_id === this.id && !client.static);

			if (client) {
				this.secret = client.client_secret;
				this.method = client.token_endpoint_auth_method;
				this.responseTypes = client.response_types;
				this.grantTypes = client.grant_types;
				this.redirectUris = client.redirect_uris;
			}

			// Update status
			this.loading = false;
		} catch (error) {
			this.loading = false;
			this.error = error.message;
			console.error(error);
		}
	},

	methods: {
		async setClient () {
			this.status = "Updating";

			try {
				await api.setClient({
					id: this.newId,
					secret: this.secret,
					method: this.method,
					redirectUris: this.redirectUris,
					grantTypes: this.grantTypes,
					responseTypes: this.responseTypes
				});

				this.$router.push({
					path: "/admin/clients"
				});
			} catch (error) {
				this.error = error.message;
				console.error(error);
			}

			this.status = undefined;
		},

		updateResponseTypes (responseTypes) {
			this.responseTypes = responseTypes;
		},

		updateGrantTypes (grantTypes) {
			this.grantTypes = grantTypes;
		},

		updateRedirectUris (redirectUris) {
			this.redirectUris = redirectUris;
		},

		back () {
			this.$router.push({
				name: "Clients"
			});
		}
	}
};
</script>

<style scoped>
.navigation {
	display: flex;
}

.navigation > * {
	width: 40%;
	margin: auto;
}
</style>
