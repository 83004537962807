<template>
	<div>
		<InputFieldButton
			v-model="newValue"
			:name="name"
			:placeholder="placeholder"
			:type="type"
			:disabled="disabled"
			@submit="add"
		/>

		<ButtonList
			:model-value="modelValue"
			:disabled="disabled"
			@update:modelValue="$emit('update:modelValue', modelValue)"
			@click="remove"
		/>
	</div>
</template>

<script>
import InputFieldButton from "./InputFieldButton";
import ButtonList		from "./ButtonList";

export default {
	name: "InputList",

	components: {
		InputFieldButton,
		ButtonList
	},

	props: {
		name: {
			type: String,
			required: true
		},

		modelValue: {
			type: Array,
			required: false,
			default: () => []
		},

		placeholder: {
			type: String,
			required: false,
			default: ""
		},

		type: {
			type: String,
			required: false,
			default: "text"
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	emits: ["update:modelValue", "add"],

	data () {
		return {
			newValue: ""
		};
	},

	computed: {
		safeName () {
			return this.name.toLowerCase().replace(/[^\w]/gi, "");
		}
	},

	methods: {
		add () {
			if (!this.newValue)
				return;

			this.$emit("update:modelValue", [...this.modelValue, this.newValue]);
			this.newValue = "";
		},

		remove (value, index) {
			if (index < 0 || index >= this.modelValue.length)
				return;

			const copy = [...this.modelValue];

			copy.splice(index, 1);

			this.$emit("update:modelValue", copy);
		}
	}
};
</script>
